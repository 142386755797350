<template>
  <div class="content-page file-manage-page manage-type-page">
    <div class="top-function-box flex al-i-c ju-c-sb">
      <div class="flex">
        <trash-affirm-com @confirm="(vm)=>deleteSelect(vm)" :disabled="selectItems.length===0" title="确定删除所选项吗?">
          <div slot="body" class="add-btn" :class="{'disabled':selectItems.length===0}">删除选中</div>
        </trash-affirm-com>
        <!-- <div class="add-btn" @click="addRow">新增</div> -->
      </div>
      <div class="search-from-box flex al-i-c">
        <Form inline :model="searchModel">
          <FormItem prop="themesName">
            <Input type="text" v-model="searchModel.themesName" placeholder="请输入主题名称"></Input>
          </FormItem>
          <FormItem prop="hallName">
            <Input type="text" v-model="searchModel.hallName" placeholder="请输入展厅名称"></Input>
          </FormItem>
        </Form>
        <div class="add-btn" @click="getData(2)">查询</div>
        <div class="add-btn" @click="getData(1)">清空查询</div>
      </div>
    </div>
    <div class="table-box m-b-20">
      <Table row-key="rowId" :load-data="handleLoadData" :height="$px2spx(520)" class="my-table-com" :columns="tableColumns" :data="tableData" :border="false" stripe @on-selection-change="tableSelectChange">
        <template slot-scope="{ row }" slot="handle">
          <div class="flex ju-c-c">
            <div class="cur-po on-line-text m-r-10" @click="editThemes(row)">
              <Icon type="md-create" />
              修改
            </div>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-box flex ju-c-end">
      <Page class-name="my-page-com" :page-size="pageSize" :current.sync="currPage" :total="total" @on-change="(page)=>getData()" show-elevator />
    </div>
  </div>
</template>

<script>
import { getToken } from '@/libs/util'
import {
  listThemes,
  listOption,
  listFils,
  deleteThemesApi,
} from '@/apis/themesApis'
import { deepCopyObj } from '@/libs/util'
import myDrawerCom from '@/components/my-drawer-com.vue'
import trashAffirmCom from '@/components/trash-affirm-com'
import { listFile } from '@/apis/themesApis'
export default {
  name: 'ThemesManagement',
  components: {
    myDrawerCom,
    trashAffirmCom,
  },
  data() {
    return {
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '展厅名称',
          key: 'hallName',
          align: 'center',
          tree: true,
        },
        {
          title: '主题名称',
          key: 'themesName',
          align: 'center',
        },
        {
          title: '展项名称',
          key: 'optionName',
          align: 'center',
        },
        {
          title: '展项类型',
          key: 'optionTypeName',
          align: 'center',
        },
        {
          title: '文件名称',
          key: 'fileName',
          align: 'center',
        },
        {
          title: '文件类型',
          key: 'fileType',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createdDate',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      tableData: [],
      pageSize: 10,
      currPage: 1,
      total: 0,
      selectItems: [],
      searchModel: {},
    }
  },
  async mounted() {
    this.getData()
  },
  methods: {
    // 获取数据
    async getData(search = 0) {
      // 清空查询 search = 1
      // 查询 search = 2
      console.log('search:', search)
      if (search === 1) {
        this.currPage = 1
        this.searchModel = {}
      }
      if (search === 2) {
        this.currPage = 1
      }
      try {
        let res = await listThemes(
          Object.assign(
            { rows: this.pageSize, currentPage: this.currPage },
            this.searchModel
          )
        )
        console.log('res:', res)
        this.tableData = res.data
        //将data 增加 children _loading
        for (let item of this.tableData) {
          item.children = []
          item._loading = false
        }
        this.total = res.count
      } catch (error) {
        console.info(error)
      }
    },

    // 选择项
    tableSelectChange(selects) {
      console.log(selects)
      this.selectItems = selects.map((item) => {
        return item.rowId
      })
    },
    // 批量删除所选项
    async deleteSelect(vm) {
      await this.submitDelete(this.selectItems.join())
      this.selectItems = []
      vm.trashSuccess()
      this.getData()
    },
    // 提交删除
    async submitDelete(ids) {
      let deleteResult = await deleteFileApi({
        ids: ids,
      })
      console.log('deleteResult:', deleteResult)
    },
    async editThemes(row) {
      this.$router.push({
        name: 'remoteControl',
        params: {
          id: row.optionId,
        },
      })
    },
    async handleLoadData(item, callback) {
      console.info(item.optionId)
      //如果是加载展项
      if (!item.optionId) {
        let res = await listOption({
          hallId: item.hallId,
          themesId: item.rowId,
        })
        let rdata = res.data
        //将data 增加 children _loading
        for (let obj of rdata) {
          // obj.children = [];
          obj._loading = false
        }
        callback(rdata)
      } else {
        //加载文件
        let res = await listFile({
          hallId: item.hallId,
          themesId: item.themesId,
          optionId: item.optionId,
        })
        let rdata = res.data
        //将data 增加 children _loading
        for (let obj of rdata) {
          obj.children = []
          obj._loading = false
        }
        console.info(rdata)
        callback(rdata)
      }
    },
  },
}
</script>

<style>
  .ivu-table-cell-tree{
    background-color: #040d33;
    color: white;
  }
</style>
