<template>
  <div class="menu-wrapper">
    <Submenu v-if="item.hasOwnProperty('children')&&item.children.length>0" :name="item.name">
      <template slot="title">
        <Row align="middle" justify="space-between" type="flex">
          <Col :span="18">
          <span>{{ item.title }}</span>
          </Col>
          <Col :span="6">
          <Icon class="m-r-15" type="md-create" @click.stop="clickitem(item, 'edit')" />
          <Icon class="m-r-15" v-if="item.parentId===0" type="md-add" @click.stop="clickitem(item, 'add')" />
          <trash-affirm-com @confirm="(vm)=>clickitem(item,'del',vm)" placement="left" :transfer="true">
            <div slot="body" class="cur-po off-line-text">
              <Icon type="md-trash"></Icon>
            </div>
          </trash-affirm-com>
          </Col>
        </Row>
      </template>
      <template v-for="(oItem, oIndex) in item.children">
        <my-menu-item :item="oItem" v-on="$listeners" :key="oIndex" />
      </template>
    </Submenu>
    <MenuItem v-else :name="item.name">
    <Row align="middle" justify="space-between" type="flex">
      <Col :span="18">
      <span>{{ item.title }}</span>
      </Col>
      <Col :span="6">
      <Icon class="m-r-15" type="md-create" @click.stop="clickitem(item, 'edit')" />
      <Icon class="m-r-15" v-if="item.parentId===0" type="md-add" @click.stop="clickitem(item, 'add')" />
      <trash-affirm-com @confirm="(vm)=>clickitem(item,'del',vm)" placement="left" :transfer="true">
        <div slot="body" class="cur-po off-line-text">
          <Icon type="md-trash"></Icon>
        </div>
      </trash-affirm-com>
      </Col>
    </Row>
    </MenuItem>
  </div>
</template>

<script>
import trashAffirmCom from '@/components/trash-affirm-com'

export default {
  name: 'my-menu-item',
  components: { trashAffirmCom },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    clickitem(e, type, vm) {
      this.$emit('clickMenu', { item: e, type: type, vm })
    },
  },
}
</script>
