<!--// 远程控制-->
<template>
  <div class="remote-control-page content-page">
    <div class="top-title-box flex al-i-c ju-c-c">
      <div class="zebra-box m-r-12">
        <zebra-marking direction="to-left" :height="14" />
      </div>
      <div class="title-text">{{currHallName}}</div>
      <div class="zebra-box m-l-12">
        <zebra-marking direction="to-right" :height="14" />
      </div>
    </div>
    <div class="tabs-box flex al-i-c ju-c-c">
      <div class="tab-item cur-po flex al-i-c ju-c-c" v-for="(item,index) in tabsList" :key="index" :class="{'on':index===currTabIndex}" @click="handleTabsClick(index)">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="main-box">
      <div class="tab-content flex ju-c-sb" v-if="currTabIndex===0">
        <div class="left-box">
          <h3 class="title m-b-24">设备实时状态展示</h3>
          <div class="table-box m-b-20">
            <Table :height="$px2spx(500)" class="my-table-com" :columns="deviceStateColumns" :data="deviceStateData" :border="false" stripe>
              <!-- <template slot-scope="{ row }" slot="state">
                <span :class="row.state?'on-line':'off-line'">{{ row.state?'在线':'离线' }}</span>
              </template>
              <template slot-scope="{ row }" slot="realStatus">
                <span>{{ row.realStatus?'播放中':'停止' }}</span>
              </template> -->
            </Table>
          </div>
          <div class="page-box flex ju-c-end">
            <Page class-name="my-page-com" :page-size="pageSize" :current.sync="deviceCurrPage" :total="deviceTotal" @on-change="getDeviceData" show-elevator />
          </div>
        </div>
        <div class="right-box">
          <h3 class="title m-b-24">控制面板</h3>
          <div class="btn-list-box flex ju-c-sb m-b-20">
            <div class="btn-box flex al-i-c ju-c-c" @click="hallPower(1)">
              <div class="btn-icon iconfont icon-guanji"></div>
              <div class="btn-text">一键开机</div>
            </div>
            <div class="btn-box flex al-i-c ju-c-c" @click="hallPower(0)">
              <div class="btn-icon iconfont icon-guanji"></div>
              <div class="btn-text">一键关机</div>
            </div>
          </div>
          <div class="btn-list-box flex ju-c-sb">
            <div class="btn-box flex al-i-c ju-c-c" @click="setThemes">
              <div class="btn-icon iconfont icon-tiaosepan"></div>
              <div class="btn-text">选择主题</div>
            </div>
            <div class="btn-box flex al-i-c ju-c-c" @click="themesModal = true">
              <div class="btn-icon iconfont icon-shezhi"></div>
              <div class="btn-text">生成主題</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content tab-content-two flex ju-c-end" v-if="currTabIndex===1">
        <div class="left-box">
          <h3 class="title m-b-24">
            <Row>
              <Col span="10">设备列表</Col>
              <Col span="14">
                <Input mycolor='true'  v-model="optName" suffix="ios-search" placeholder="查询" style="width: auto;"/>
              </Col>
            </Row>

          </h3>
          <div class="device-list-box custom-scrollbar">
            <div class="device-item cur-po m-b-40" :class="{'curr':item.rowId===currMediaId}" v-for="(item,index) in mediaDeviceData" :key="index" @click="getMediaData(item)">
              <div class="name">
                <div>{{item.optionName}}</div>
                <span style="font-size: 10px" :class="item.realStatus==='on'?'on-line':'off-line'">{{item.realStatusName?item.realStatusName:'离线'}}</span>
                <span style="font-size: 10px" class="argument-box m-l-40">{{item.optionTypeName }}</span>
              </div>
              <div class="argument-box projectorClz" v-for="pt in item.lstProjector" :key="pt.rowId">
                <div class="ptorClz">{{pt.projectorName }}</div>
                <!--                <div class="ptorClz" style="text-align: right">-->
                <!--                  <i-switch v-model="pt.realStatus" true-value="true" false-value="false" :before-change="powerProjector" @on-change="powerProjector2(pt)">-->
                <!--                  <template #open>-->
                <!--                    <span>开</span>-->
                <!--                  </template>-->
                <!--                  <template #close>-->
                <!--                    <span>关</span>-->
                <!--                  </template>-->
                <!--                  </i-switch>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="flex m-b-24 ju-c-sb">
            <div v-if="showList" class="table-box m-b-20">
              <h3 class="title m-b-24">播放清单</h3>
              <Table class="my-table-com" :height="$px2spx(300)" :columns="mediaColumns" :data="mediaData" :border="false" stripe>
                <template slot-scope="{ row }" slot="handlePlay">
                  <div class="flex ju-c-c">
                    <div class="cur-po on-line-text m-r-10" @click="ctlPlay(1,row.fileUrl)">
                      <Icon type="ios-play" />
                      播放
                    </div>
                    <div slot="body" class="cur-po off-line-text" @click="ctlPlay(0,row.fileUrl)">
                      <Icon type="ios-pause" />
                      暂停
                    </div>
                  </div>
                </template>
              </Table>
              <div class="m-t-10">
                <RadioGroup v-model="mediaPlayType" @on-change="changePlayMethod">
                  <div class="play-type flex al-i-c">
                    <div class="label">播放方式</div>
                    <div class="label">
                      <Radio label="auto">
                        <span>循环播放</span>
                      </Radio>
                    </div>
                    <div class="label">
                      <Radio label="manual">
                        <span>单次播放</span>
                      </Radio>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
            <div class="media-preview flex al-i-c ju-c-c" @click="getPlayUrl">
              <template v-if="streamStatus===0">
                <img class="video-p" src="../assets/images/temp/video-temp.png" alt="">
                <div class="play-btn cur-po anim iconfont icon-bofang"></div>
              </template>
              <video-com v-if="showChild" ref="videoCom" />
            </div>
          </div>
          <div class="control-panel">
            <h3 class="title m-b-24">控制面板</h3>
            <div class="btn-list-box flex al-i-c">
              <div class="btn-box" v-if="showList" @click="openSelectFilePopup(0)">
                <span class="iconfont icon-daiji" />
                <span>设置待机</span>
              </div>
              <div class="btn-box" v-if="showList" @click="openSelectFilePopup(1)">
                <span class="iconfont icon-zhengpian" />
                <span>设置正片</span>
              </div>
              <div class="btn-box">
                <Poptip title="调节音量" :width="$px2spx(300)" popper-class="trash-affirm-poptip" v-model="visible">
                  <div class="btn-box-content">
                    <span class="iconfont icon-controls" />
                    <span>调节音量</span>
                  </div>
                  <div slot="content" style="padding:20px 10px 0px 10px;">
                    <div style="margin:0 20px;">
                      <Slider v-model="volumeNum"></Slider>
                    </div>
                    <div style="text-align: right;margin-top: 20px">
                      <Button type="primary" @click="volumeChange">提交</Button>
                    </div>
                  </div>
                </Poptip>
              </div>
              <div class="btn-box" v-if="showList" @click="ctlPlay(2)">
                <span class="iconfont icon-bofang1" />
                <span>结束播放</span>
              </div>
              <div class="btn-box" v-if="showList" @click="closeStream">
                <span class="iconfont icon-bofang1" />
                <span>停止推流</span>
              </div>
              <div class="btn-box"  @click="remoteUpdateFile">
                <span class="iconfont icon-bofang1" />
                <span>更新资源</span>
              </div>
              <div class="btn-box" @click="optionPower(1)">
                <span class="iconfont icon-guanji" />
                <span>开机</span>
              </div>
              <div class="btn-box" @click="optionPower(0)">
                <span class="iconfont icon-guanji" />
                <span>关机</span>
              </div>
              <div class="btn-box" @click="optionPower(2)">
                <span class="iconfont icon-guanji" />
                <span>重启</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content" v-if="currTabIndex===2">
        <div class="table-box m-b-20">
          <Table class="my-table-com" :columns="otherDeviceColumns" :data="otherDeviceData" :border="false" stripe>
            <template slot-scope="{ row }" slot="state">
              <span :class="row.state?'on-line':'off-line'">{{ row.state?'在线':'离线' }}</span>
            </template>
            <template slot-scope="{ row }" slot="property">
              <span>{{ row.property?'播放中':'停止' }}</span>
            </template>
            <template slot-scope="{ row }" slot="handle">
              <div class="tabs-box flex al-i-c ju-c-c">
                <div class="tab-item flex al-i-c ju-c-c" v-for="(item,index) in handleBtnList" :key="index" @click="handleBtnClick(item,row)">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </template>
          </Table>
        </div>
        <div class="page-box flex ju-c-end">
          <Page class-name="my-page-com" :page-size="pageSize" :current.sync="otherDeviceCurrPage" :total="otherDeviceTotal" @on-change="getOtherDeviceData" show-elevator />
        </div>
      </div>
    </div>
    <div class="pop-up-box flex al-i-c ju-c-c" v-if="editFileDialog">
      <div class="pop-up-content" style="width: 610px;height: 380px;background-size: 600px 380px;">
        <div class="iconfont icon-guanbi popup-close-btn cur-po" @click="hidePopup"></div>
        <div class="title-box flex al-i-c ju-c-sb">
          <span class="title">更新资源</span>
          <div class="zebra-box">
            <zebra-marking direction="to-right" :height="14" />
          </div>
        </div>
        <div class="m-t-10">
          <div class="table-box m-b-20">
            <Table :height="$px2spx(260)" class="my-table-com" :columns="remoteFileCol" :data="remoteFileData" :border="false">
              <template #files="{ row,index }">
                <Select v-model="remoteFileData[index].files" filterable multiple  >
                  <Option v-for="item in remoteFileList" :value="item.rowId" :key="item.rowId">{{ item.fileName }}</Option>
                </Select>
              </template>
            </Table>
          </div>
          <!--          <Form :label-width="100">-->
<!--            <FormItem label="选择文件夹:">-->
<!--              <Select v-model="selectFolders" placeholder="请选择需要同步的文件夹">-->
<!--                <Option v-for="(item,index) in localFolders" :value="item" :key="index">{{ item }}</Option>-->
<!--              </Select>-->
<!--            </FormItem>-->
<!--            <FormItem label="上传资源：">-->
<!--              <Upload class="my-upload" type="drag" accept=".pdf,image/*,audio/*,video/*"  :on-success="fileUploadSuccess" :action="uploadFileApi">-->
<!--                <div style="padding: 20px 0">-->
<!--                  <Icon type="ios-cloud-upload" size="32" style="color: #3399ff"></Icon>-->
<!--                  <p>点击或拖动文件到此处进行上传</p>-->
<!--                </div>-->
<!--              </Upload>-->
<!--            </FormItem>-->
<!--          </Form>-->
        </div>
        <div class="flex al-i-c ju-c-end" style="margin-top: 10px;">
<!--          <Button type="primary" loading="loading"  @click="editFileSubmit">同步</Button>-->

          <div class="submit-select-btn"  @click="editFileSubmit">同步</div>
        </div>
      </div>
    </div>
    <div class="pop-up-box flex al-i-c ju-c-c" v-if="themesModal">
      <div class="pop-up-content" style="width: 610px;height: 330px;background-size: 600px 330px;">
        <div class="iconfont icon-guanbi popup-close-btn cur-po" @click="hidePopup"></div>
        <div class="title-box flex al-i-c ju-c-sb">
          <span class="title">生成主题</span>
          <div class="zebra-box">
            <zebra-marking direction="to-right" :height="14" />
          </div>
        </div>
        <div class="my-from-box m-t-40">
          <Form :label-width="80">
            <FormItem label="主题名称:">
              <Input v-model="themesName" placeholder="请输入"></Input>
            </FormItem>
            <FormItem label="备注：">
              <Input v-model="themesMemo" placeholder="请输入"></Input>
            </FormItem>
          </Form>
        </div>
        <div class="flex al-i-c ju-c-end" style="margin-top: 70px;">
          <div class="submit-select-btn" @click="createdThemes">生成</div>
        </div>
      </div>
    </div>
    <div class="pop-up-box flex al-i-c ju-c-c" v-if="isShowPopup">
      <div class="pop-up-content">
        <div class="iconfont icon-guanbi popup-close-btn cur-po" @click="hidePopup"></div>
        <div class="title-box flex al-i-c ju-c-sb">
          <span class="title">选择文件</span>
          <div class="zebra-box">
            <zebra-marking direction="to-right" :height="14" />
          </div>
        </div>
        <div class="popup-search-box flex al-i-c ju-c-sb">
          <input class="popup-search-input" placeholder="请输入搜索文件名称" v-model="fileName" type="text" />
          <i @click="getFile" class="iconfont icon-sousuo" />
        </div>
        <div class="results-list-box">
          <div class="results-list custom-scrollbar">
            <div class="results-item flex al-i-c ju-c-sb" v-for="(item,index) in fileList" :key="index">
              <div class="results-title">{{item.fileName}}</div>
              <div class="to-btn" @click="selectItemFile(item)" :class="{'selected':selectFiles.indexOf(item.rowId)!==-1}">{{selectFiles.indexOf(item.rowId)!==-1?'已选择':'选择'}}</div>
            </div>
          </div>
        </div>
        <div class="flex al-i-c ju-c-end">
          <div class="submit-select-btn" @click="sbumitFileUpdate">提交修改</div>
        </div>
      </div>
    </div>
    <div class="pop-up-box flex al-i-c ju-c-c" v-if="selectThemes">
      <div class="pop-up-content">
        <div class="iconfont icon-guanbi popup-close-btn cur-po" @click="hidePopup"></div>
        <div class="title-box flex al-i-c ju-c-sb">
          <span class="title">设置主题</span>
          <div class="zebra-box">
            <zebra-marking direction="to-right" :height="14" />
          </div>
        </div>
        <div class="popup-search-box flex al-i-c ju-c-sb">
          <input class="popup-search-input" placeholder="请输入搜索主题名称" type="text" />
          <i class="iconfont icon-sousuo" />
        </div>
        <div class="results-list-box">
          <div class="results-list custom-scrollbar">
            <div class="results-item flex al-i-c ju-c-sb" v-for="(item,index) in themesList" :key="index">
              <div class="results-title">{{item.themesName}}</div>
              <div class="to-btn" @click="themesId=item.rowId" :class="{'selected':themesId==item.rowId}">{{themesId==item.rowId?'已选择':'选择'}}</div>
            </div>
          </div>
          <div class="flex al-i-c ju-c-end">
            <div class="submit-select-btn" @click="themesUpdate">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // remoteControlDeviceDataApi,
  // remoteControlDeviceMediaDataApi,
  getStreamApi,
  setAwaitFileApi,
  setPlayFileApi,
  remoteControlMediaDataApi,
  remoteControlOtherDeviceDataApi,
  setPlayMethod,
  setVoice,
  optionPower,
  hallPower,
  ctlPlay,
  editFile,
  projectorPower,
} from '@/apis/remoteControlApis'
import { listThemes, selectThemes, creatThemesApi } from '@/apis/themesApis'
import { hallOptionDataApi } from '@/apis/exhibitionManagementApis'
import { getFileDataApi, uploadFileApi } from '@/apis/fileManageApis'
import zebraMarking from '@/components/zebra-marking'
import videoCom from '@/components/video-com'

export default {
  name: 'RemoteControl',
  components: {
    zebraMarking,
    videoCom,
  },
  data() {
    return {
      optName:"",
      uploadFileApi,
      selectThemes: false,
      showChild: true,
      editFileDialog: false,
      themesList: [],
      themesId: '',
      selectFolders: '',
      selectFile: '',
      localFolders: [],
      themesModal: false,
      loading: false,
      themesName: '',
      themesMemo: '',
      tabsList: [
        {
          name: '展厅控制',
        },
        {
          name: '多媒体控制',
        },
        // {
        //   name: '其他设备'
        // },
      ],
      visible: false,
      currHallName: '',
      currMediaId: 0,
      showList: true,
      currTabIndex: 0,
      deviceStateColumns: [
        {
          title: '名称',
          key: 'optionName',
          align: 'center',
        },
        {
          title: '状态',
          key: 'realStatusName',
          align: 'center',
        },
        {
          title: '属性',
          key: 'optionTypeName',
          align: 'center',
        },
        {
          title: '更新时间',
          key: 'realTime',
          align: 'center',
        },
      ],
      deviceStateData: [],
      deviceTotal: 0,
      deviceCurrPage: 1,
      pageSize: 100,
      mediaDeviceData: [],
      mediaColumns: [
        {
          title: '名称',
          key: 'fileName',
          align: 'center',
        },
        {
          title: '类型',
          key: 'fileTypeName',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handlePlay',
          align: 'center',
        },
      ],
      mediaData: [],
      mediaPlayType: 'auto',
      otherDeviceColumns: [
        {
          title: '名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '属性',
          slot: 'property',
          align: 'center',
        },
        {
          title: '状态',
          slot: 'state',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'handle',
          align: 'center',
        },
      ],
      otherDeviceData: [],
      otherDeviceTotal: 0,
      otherDeviceCurrPage: 1,
      handleBtnList: [
        {
          name: '音量',
          event: 'volume',
        },
        {
          name: '开机',
          event: 'action',
        },
        {
          name: '关机',
          event: 'shutdown',
        },
      ],
      streamStatus: 0,
      isShowPopup: false,
      fileName: '',
      fileList: [],
      selectFileType: 0,
      selectFiles: [],
      volumeNum: 50,
      remoteFileList:[],
      remoteFileData:[
        {"folder":"imgs2",files:[]},
        {"folder":"imgs3",files:[]},
      ],
      remoteFileCol:[
        {title:"文件目录",key:"folder",width:200,align:"center"},
        {title:"选择文件","slot":"files",align:"center"},
      ],
    }
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        if (this.$route.matched[1].path === '/remoteControl/:id/:hallName') {
          this.currTabIndex = 0
          this.deviceStateData = []
          this.mediaDeviceData = []
          this.mediaData = []
          this.currHallName = this.$route.params.hallName
          if (this.currTabIndex === 0) {
            this.getDeviceData()
          } else if (this.currTabIndex === 1) {
            this.getInitMediaData()
          } else if (this.currTabIndex === 2) {
            this.getOtherDeviceData()
          }
        } else {
          console.log('跳转到其他页面')
          // 跳转到其他页面的时候先关闭推流
          if (this.streamStatus === 1) {
            this.closeStream()
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    // this.getDeviceData()
    setInterval(() => {
      if (this.currTabIndex === 0) {
        this.getDeviceData()
      } else if (this.currTabIndex === 1) {
        this.getInitMediaData()
      } else if (this.currTabIndex === 2) {
        this.getOtherDeviceData()
      }
    }, 5000)

      let res = await getFileDataApi({
        hallId: this.$route.params.id,
        rows:100,
      })
      this.remoteFileList = res.data
  },
  methods: {
    // 文件上传成功
    fileUploadSuccess(res, file) {
      console.log(res, file)
      this.selectFile = res.data.url
    },
    async powerProjector2(pt) {
      //获报投影仪当前状态
      let curStauts = pt.realStatus
      if (curStauts == 'true') {
        //关闭投影仪
        let res = await projectorPower({
          optionId: pt.optionId,
          deviceNum: pt.deviceNum,
          power: 0,
        })
      } else {
        //开启投影仪
        let res = await projectorPower({
          optionId: pt.optionId,
          deviceNum: pt.deviceNum,
          power: 1,
        })
      }
    },
    powerProjector() {
      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: '投影仪开/关',
          content: '您确认要切换开关状态吗？',
          onOk: () => {
            resolve()
          },
        })
      })
    },
    // 获取设备实时状态列表数据
    async getDeviceData() {
      let res = await hallOptionDataApi({
        hallId: this.$route.params.id,
        rows: this.pageSize,
        currentPage: this.deviceCurrPage,
      })
      console.log('res:', res)
      this.deviceStateData = res.data
      this.deviceTotal = res.count
      if (this.deviceTotal == 0) {
        this.$Message.error('该展厅未配置展项，请确认数据是否完整。')
      }
    },
    // 获取设备列表——多媒体控制
    async getInitMediaData() {
      let deviceRes = await hallOptionDataApi({
        hallId: this.$route.params.id,
        rows: this.pageSize,
        optionName: this.optName,
        currentPage: this.deviceCurrPage,
      })
      this.mediaDeviceData = deviceRes.data
      if (this.mediaDeviceData.length > 0) {
        if(this.currMediaId ==0){
          this.currMediaId = this.mediaDeviceData[0].rowId
        }
        //更新选中的元素
        const lstItem = this.mediaDeviceData.filter(item=>{
          return item.rowId == this.currMediaId
        })
        let curItem = lstItem[0]
        this.showList = curItem.optionType === 'ctl'
        //刷新播放清单
        if(this.showList){
          let res = await remoteControlMediaDataApi({
            optionId: this.currMediaId,
          })
          this.mediaData = res.data
        }
        if(curItem.localFolders) this.localFolders = JSON.parse(curItem.localFolders);

      } else {
        this.$Message.error('该展厅未配置展项，请确认数据是否完整。')
      }
    },
    // 获取多媒体文件列表——多媒体控制
    async getMediaData(item) {
      //设置文件夹清单
      if(item.localFolders) this.localFolders = JSON.parse(item.localFolders);
      console.log(this.localFolders)
      // 切换设备的时候先关闭推流
      if (this.streamStatus === 1) {
        this.closeStream()
      }
      this.currMediaId = item.rowId
      if (item.optionType === 'show') {
        this.showList = false
      } else if (item.optionType === 'ctl') {
        this.showList = true
      }
      let res = await remoteControlMediaDataApi({
        optionId: item.rowId,
      })
      console.log('res:', res)
      this.mediaData = res.data
    },
    // 其他列表
    async getOtherDeviceData() {
      let res = await remoteControlOtherDeviceDataApi({
        page: this.otherDeviceCurrPage,
        size: this.pageSize,
      })
      console.log('res:', res)
      this.otherDeviceData = res.data.list
      this.otherDeviceTotal = res.data.total
    },
    handleTabsClick(index) {
      // const item = this.tabsList[index]
      if (this.currTabIndex === index) {
        return
      }
      // 切换tab时先关闭推流
      if (this.streamStatus === 1) {
        this.closeStream()
      }
      this.currTabIndex = index
      if (index === 0) {
        this.optName=""
        this.getDeviceData()
      } else if (index === 1) {
        this.getInitMediaData()
      } else if (index === 2) {
        this.getOtherDeviceData()
      }
    },
    // 全屏视频
    playVideo(video_url) {
      this.showChild = false
      let that = this;
      this.$nextTick(()=>{
        that.showChild = true;
        that.$nextTick(()=>{
          that.$refs.videoCom.init(video_url)
        })
      })
    },
    // 获取推流地址
    async getPlayUrl() {
      if (this.streamStatus === 0) {
        try {
          let streamRes = await getStreamApi({
            optionId: this.currMediaId,
            ctlType: 1,
          })
          console.log(streamRes)
          this.playVideo(streamRes.data)
          this.streamStatus = 1
        } catch (error) {
          console.log(error)
          this.streamStatus = 0
        }
      }
    },

    //更新资源文件
    async editFileSubmit(){
      let that = this
      this.$Modal.confirm({
        title: '更新资源',
        content: '确认更新资源吗？',
        onOk: async () => {
          let rst = await editFile({
            optionId: that.currMediaId,
            selectFolders: that.remoteFileData
          })
          that.loading = false;
          this.$Message.success('操作成功')
          this.editFileDialog = false;
        },
        onCancel: () => {},
      })
    },
    //更新远程文件
    async remoteUpdateFile(){
      this.remoteFileData = []
      const lstItem = this.mediaDeviceData.filter(item=>{
        return item.rowId == this.currMediaId
      })
      let folders = lstItem[0].localFolders
      if(!folders){
        this.$Message.error("该展项未配置文件夹，请联系管理员。")
        return;
      }
      let ary = JSON.parse(folders);
      for(let str of ary){
        let obj = {};
        obj["folder"] = str
        obj["files"] =[]
        this.remoteFileData.push(obj)
      }
      this.editFileDialog = true;
    },
    // 关闭推流
    async closeStream() {
      try {
        let closeStreamRes = await getStreamApi({
          optionId: this.currMediaId,
          ctlType: 2,
        })
        console.log(closeStreamRes)
        this.streamStatus = 0
      } catch (error) {
        console.log(error)
      }
    },
    handleBtnClick(item, row) {
      let event = item.event
      this[event](row)
    },
    volume(data) {
      console.log('volume:', data)
    },
    action(data) {
      console.log('action:', data)
    },
    shutdown(data) {
      console.log('shutdown:', data)
    },
    // 获取文件列表
    async getFile() {
      let res = await getFileDataApi({
        fileName: this.fileName,
        hallId: this.$route.params.id,
      })
      console.log(res)
      this.fileList = res.data
    },
    // 显示选择文件弹出框
    async openSelectFilePopup(type) {
      this.selectFileType = type
      this.selectFiles = []
      await this.getFile()
      this.isShowPopup = true
    },
    // 隐藏选择文件弹出框
    hidePopup() {
      this.isShowPopup = false
      this.themesModal = false
      this.selectThemes = false
      this.editFileDialog = false
    },
    // 选择当前文件
    selectItemFile(item) {
      if (this.selectFiles.indexOf(item.rowId) !== -1) {
        this.selectFiles.splice(this.selectFiles.indexOf(item.rowId), 1)
      } else {
        if (this.selectFileType === 0) {
          // 待机文件，单选
          this.selectFiles = [item.rowId]
        } else if (this.selectFileType === 1) {
          // 正片文件，多选
          this.selectFiles.push(item.rowId)
        }
      }
    },
    //修改播放模式
    async changePlayMethod(val) {
      let that = this
      this.$Modal.confirm({
        title: '修改播放模式',
        content: '确认修改播放模式吗？',
        onOk: async () => {
          let rst = await setPlayMethod({
            optionId: that.currMediaId,
            playMethod: that.mediaPlayType,
          })
          that.$Message.success('操作成功')
        },
        onCancel: () => {
          if (val == 'auto') that.mediaPlayType = 'manual'
          if (val == 'manual') that.mediaPlayType = 'auto'
        },
      })
    },
    // 提交文件修改
    async sbumitFileUpdate() {
      try {
        if (this.selectFileType === 0) {
          // 待机文件
          await setAwaitFileApi({
            optionId: this.currMediaId,
            fileRowIds: this.selectFiles.join(),
          })
        } else if (this.selectFileType === 1) {
          // 正片文件
          await setPlayFileApi({
            optionId: this.currMediaId,
            fileRowIds: this.selectFiles.join(),
            playMethod: this.mediaPlayType,
          })
        }
        this.isShowPopup = false
        // 更新成功后需要刷新一下播放清单
        let res = await remoteControlMediaDataApi({
          optionId: this.currMediaId,
        })
        console.log('res:', res)
        this.mediaData = res.data
      } catch (error) {
        console.log(error)
      }
    },
    // 调节音量
    async volumeChange() {
      let that = this
      this.$Modal.confirm({
        title: '调节音量',
        content: '确认调节音量吗？',
        onOk: async () => {
          let rst = await setVoice({
            optionId: that.currMediaId,
            voiceVal: that.volumeNum,
          })
          this.$Message.success('操作成功')
          this.visible = false
        },
        onCancel: () => {},
      })
    },
    // 控制播放
    async ctlPlay(flag, filePath) {
      console.info(filePath)
      let that = this
      let str
      if (flag == 1) str = '播放'
      if (flag == 0) str = '暂停播放'
      if (flag == 2) str = '结束播放'
      this.$Modal.confirm({
        title: '播放控制',
        content: '确认' + str + '吗？',
        onOk: async () => {
          let rst = await ctlPlay({
            optionId: that.currMediaId,
            filePath: filePath,
            play: flag,
          })
          this.$Message.success('操作成功')
          this.visible = false
        },
        onCancel: () => {},
      })
    },
    // 展项开关机
    async optionPower(flag) {
      let that = this
      let str
      if (flag == 1) str = '开机'
      if (flag == 0) str = '关机'
      if (flag == 2) str = '重启'
      this.$Modal.confirm({
        title: '开关机操作',
        content: '确认' + str + '吗？',
        onOk: async () => {
          let rst = await optionPower({
            optionId: that.currMediaId,
            power: flag,
          })
          this.$Message.success('操作成功')
          this.visible = false
        },
        onCancel: () => {},
      })
    },
    // 一键开关机
    async hallPower(flag) {
      let that = this
      this.$Modal.confirm({
        title: '一键开关机',
        content: '确认' + (flag == 1 ? '开机' : '关机') + '吗？',
        onOk: async () => {
          let rst = await hallPower({
            hallId: that.$route.params.id,
            power: flag,
          })
          this.$Message.success('操作成功')
          this.visible = false
        },
        onCancel: () => {},
      })
    },
    // 生成主题
    async createdThemes(flag) {
      if (this.themesName == '') {
        this.$Message.error('主题名称不能空。')
        this.loading = false
        return
      }
      await creatThemesApi({
        hallId: this.$route.params.id,
        themesName: this.themesName,
        themesMemo: this.themesMemo,
      })
      this.themesModal = false
    },
    async setThemes() {
      let hallId = this.$route.params.id
      let rst = await listThemes({ hallId: hallId })
      console.info(rst)
      this.themesList = rst.data
      this.selectThemes = true
    },
    async themesUpdate() {
      if (this.themesId == '') {
        this.$Message.error('请选择需要设置的主题')
        return
      }
      let rst = await selectThemes({ rowId: this.themesId })
      this.hidePopup()
    },
  },
}
</script>

<style lang="scss" scoped>
  /deep/ .ivu-input-wrapper[mycolor='true'] .ivu-input-with-suffix{
    background-color: #0d1f4b;
    color: white;
  }
  /deep/ .ivu-select-selection {
    background-color: #050b33;
    border: 1px solid #0585ef;
  }
.remote-control-page {
  margin: 0 34px;
  padding: 20px 40px;
  border: 1px solid #092a6e;
  box-shadow: 0 0 20px 6px #090f3b inset;

  .top-title-box {
    margin-bottom: 20px;

    .zebra-box {
      width: 200px;
    }

    .title-text {
      color: #1ef2f3;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .tabs-box {
    .tab-item {
      width: 91px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      color: #9a9bab;
      margin-right: 20px;
      background-image: url('../assets/images/tag-bg.png');
      background-size: 91px 30px;
      background-repeat: no-repeat;

      &.on {
        background-image: url('../assets/images/tag-bg-on.png');
        color: #fff;
      }
    }
  }

  .main-box {
    margin-top: 34px;

    .title {
      color: #fff;
      font-size: 22px;
      height: 33px;
    }

    .tab-content {
      .on-line {
        color: #1ef2f3;
      }

      .off-line {
        color: #d75659;
      }

      .left-box {
        width: 1450px;
        border: 1px solid #092a6e;
        padding: 24px;
      }

      .right-box {
        width: 280px;
        padding: 24px;
        border: 1px solid #092a6e;

        .btn-list-box {
          .btn-box {
            cursor: pointer;
            width: 100px;
            height: 140px;
            border-radius: 10px;
            text-align: center;
            flex-direction: column;
            background-color: #081e4e;
            margin-right: 20px;

            &.on,
            &:hover {
              background-color: #1b84d5;
              color: #fff;

              .btn-icon {
                color: #fff;
              }
            }

            .btn-icon {
              font-size: 30px;
              color: #1ef2f3;
              margin-bottom: 10px;
            }

            .btn-text {
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }

    .tab-content-two {
      position: relative;

      .left-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 280px;
        height: 100%;
        padding: 24px 0;

        > .title {
          padding: 0 24px;
        }

        .device-list-box {
          height: calc(100% - 57px);
          overflow-y: auto;
          margin-right: 5px;

          .device-item {
            margin: 0 10px;
            padding: 14px;
            border: 1px solid transparent;
            border-radius: 10px;

            &:hover,
            &.curr {
              border-color: #0c356c;
              box-shadow: 0 0 20px 6px #090f3b inset;
            }

            .name {
              font-size: 16px;
              color: #fff;
              margin-bottom: 5px;
            }

            .argument-box {
              font-size: 12px;
            }
          }
        }
      }

      .right-box {
        width: 1450px;
        border: 0;
        padding: 0;

        .table-box {
          width: calc(100% - 760px);
        }

        .play-type {
          color: #fff;
          font-size: 14px;

          .label {
            margin-right: 10px;
          }

          /deep/ .ivu-radio-wrapper {
            .ivu-radio {
              .ivu-radio-inner {
                border-color: #686980;
                background-color: #03052c;
                border-width: 2px;
                border-radius: 100%;

                &::after {
                  width: 8px;
                  height: 8px;
                  background-color: #1ef2f3;
                }
              }
            }

            &.ivu-radio-wrapper-checked {
              .ivu-radio {
                .ivu-radio-inner {
                  border-color: #1ef2f3;
                }
              }
            }
          }
        }

        .media-preview {
          width: 720px;
          height: 390px;
          margin-left: 40px;
          position: relative;

          .video-p {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
          }

          .play-btn {
            position: absolute;
            font-size: 100px;
            z-index: 100;
            color: #1ef2f3;
          }
        }

        .control-panel {
          .btn-list-box {
            .btn-box {
              cursor: pointer;
              width: 160px;
              height: 60px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              flex-direction: row;
              .btn-box-content {
                width: 160px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .iconfont {
                margin-right: 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  .pop-up-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);

    .pop-up-content {
      width: 600px;
      height: 670px;
      padding: 30px 35px;
      background-image: url('../assets/images/pop-up-box.png');
      background-repeat: no-repeat;
      background-size: 600px 670px;
      position: relative;

      .popup-close-btn {
        width: 30px;
        height: 30px;
        font-size: 30px;
        position: absolute;
        right: -40px;
        top: -40px;
        color: #197bc6;
      }

      .title-box {
        margin-bottom: 24px;

        .title {
          font-size: 22px;
          color: #fff;
          margin-right: 20px;
        }

        .zebra-box {
          flex: 1;
        }
      }

      .popup-search-box {
        width: 530px;
        height: 50px;
        padding: 0 15px;
        margin-bottom: 14px;
        background-image: url('../assets/images/pop-up-box-search.png');
        background-repeat: no-repeat;
        background-size: 530px 50px;

        .popup-search-input {
          background-color: transparent;
          border: 0;
          outline: none;
          color: #fff;
          font-size: 16px;
          width: 80%;

          &::placeholder {
            color: #233a67;
            font-size: 16px;
          }
        }

        .iconfont {
          color: #1de5e9;
        }
      }

      .results-list-box {
        height: 460px;

        .results-list {
          width: 102%;
          height: 100%;
          padding-right: 2%;
          overflow-y: auto;
        }

        .results-item {
          padding: 14px 0;
          border-bottom: 1px solid #1d1e3b;

          .results-title {
            font-size: 16px;
            color: #fff;
          }

          .to-btn {
            width: 91px;
            height: 30px;
            line-height: 30px;
            background-image: url('../assets/images/tag-bg-on.png');
            background-size: 91px 30px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            &.selected {
              color: #999;
            }
          }
        }
      }
      .submit-select-btn {
        width: 91px;
        height: 30px;
        line-height: 30px;
        background-image: url('../assets/images/tag-bg-on.png');
        background-size: 91px 30px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  /deep/.trash-affirm-poptip {
    .trash-btn {
      overflow: hidden;
    }
    .ivu-poptip-arrow {
      &::after {
        border-top-color: #090f3b !important;
      }
    }
    .ivu-poptip-inner {
      background-color: rgba($color: #090f3b, $alpha: 0.8);
      border: 1px solid #092a6e;
      box-shadow: 0 0 20px 6px #090f3b inset;
      .ivu-poptip-title {
        border-bottom: 1px solid #092a6e;
        .ivu-poptip-title-inner {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .ivu-poptip-body {
      text-align: left;
      padding: 10px;
      .ivu-alert-warning {
        border: 0;
        background-color: unset;
        .ivu-alert-icon {
          top: 8px;
          left: 20px;
        }
        .ivu-alert-message {
          color: #fff;
        }
      }
    }
    .ivu-poptip-title:after {
      display: none;
    }
    .ivu-poptip-footer {
      .ivu-btn-text {
        display: none;
      }
    }
  }
  .projectorClz {
    margin-bottom: 8px;
    padding-left: 25px;
    text-align: match-parent;
  }
  .ptorClz {
    display: inline-block;
    width: 100%;
    font-size: 12px;
  }
}
</style>
