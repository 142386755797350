<template>
  <div class="data-analyse-page content-page">
    <Row>
      <Col span="16">
        <div class="hallImg">
          <div class="leftImg"><img src="../assets/images/left.png" width="100%" style="cursor: pointer" @click="prevClick"/></div>
          <div class="centerImg">
            <div style="position: relative;height: 100%">
              <div class="top-title-box flex al-i-c ju-c-c">
                <div class="zebra-box m-r-12">
                  <zebra-marking direction="to-left" :height="14" />
                </div>
                <div class="title-text">{{ currHallName }}</div>
                <div class="zebra-box m-l-12">
                  <zebra-marking direction="to-right" :height="14" />
                </div>
              </div>
              <div style="width: 100%;height: calc(100% - 80px);">
                <div v-if="hallFile[0]" style="width: 100%;height: 100%;overflow: hidden">
                        <div v-if="hallFile[0].indexOf('.mp4')>0"  id="J_prismPlayer" style="width: 100%;" height="100%" ></div>
                        <Carousel v-else v-model="imgIndex" loop arrow="never">
                          <CarouselItem v-for="item in hallFile" >
                              <img  :src="item" style="width: 100%" />
                          </CarouselItem>
                        </Carousel>
                </div>
                <img v-else src="../assets/images/demo.png" width="100%" height="100%"/>

              </div>
            </div>
          </div>
          <div class="rightImg"><img src="../assets/images/right.png" width="100%" style="cursor: pointer" @click="nextClick"/></div>
          <div style="clear: both"></div>
        </div>
        <Row>
          <Col span="12">
              <div class="chunk-item" style="width: 100%;height: 100%;">
                <div id="map" style="width: 100%;height: 100%;"></div>
              </div>
          </Col>
          <Col span="12" style="height: 100%">
            <div class="chunk-item" style="width: 100%">
              <div class="title-box flex al-i-c ju-c-sb">
                <div class="title-text">每日客流数据统计</div>
                <div class="title-line">
                  <zebra-marking :height="14" />
                </div>
              </div>
              <block v-if="showPassenger">
                <div class="echart-view">
                  <vue-echarts :option="chartsOption4" style="height: 100%" ref="guestChart"/>
                </div>
              </block>
              <div v-else class="text-c no-data-box">
                <div>暂无客流数据分析</div>
                <img class="no-data-img" src="../assets/images/worksheet.png" alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span="8">
        <div class="chunk-item">
          <div class="title-box flex al-i-c ju-c-sb">
            <div class="title-text">资源文件夹统计</div>
            <div class="title-line">
              <zebra-marking :height="14" />
            </div>
          </div>
          <div class="echart-view">
            <vue-echarts :option="chartsOption1" style="height: 100%"  ref="fileChart"/>
          </div>
        </div>
        <div class="chunk-item">
          <div class="title-box flex al-i-c ju-c-sb">
            <div class="title-text">设备统计</div>
            <div class="title-line">
              <zebra-marking :height="14" />
            </div>
          </div>
          <div class="echart-view">
            <vue-echarts :option="chartsOption2" style="height: 100%" ref="optCharts"/>
          </div>
        </div>
        <div class="chunk-item">
          <div class="title-box flex al-i-c ju-c-sb">
            <div class="title-text">近10日能耗分析</div>
            <div class="title-line">
              <zebra-marking :height="14" />
            </div>
          </div>
          <block v-if="showEnergy">
            <div class="echart-view">
              <vue-echarts :option="chartsOption3" style="height: 100%" ref="energyCharts"/>
            </div>
          </block>
          <div v-else class="text-c no-data-box">
            <div>暂无能耗数据分析</div>
            <img
                    class="no-data-img"
                    src="../assets/images/worksheet.png"
                    alt=""
            />
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { dataAnalyseDataApi } from '@/apis/dataAnalysisApis'
import { detailHallApi } from '@/apis/exhibitionManagementApis'
import zebraMarking from '@/components/zebra-marking'
import videoCom from '@/components/video-com'

export default {
  name: 'DataAnalyse2',
  components: {
    zebraMarking,
    videoCom
  },
  data() {
    return {
      map:null,
      imgIndex:0,
      geocoder:null,
      marker:null,
      currHallName: '',
      hallFile: [],
      hallCity: '',
      chartsOption1: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['文件总数', '展项使用量'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        grid: {
          left: '3%',
          right: '1%',
          bottom: '1%',
          top:5,
          containLabel: true
        },
        series: [
          {
            name: '文件总数',
            type: 'bar',
            color: '#f6c26e',
            data: [],
            barWidth: this.$px2spx(8),
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: '展项使用量',
            type: 'bar',
            color: '#1ef2f3',
            data: [],
            barWidth: this.$px2spx(8),
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      },
      chartsOption2: {
        legend: {
          top: 'middle',
          right: this.$px2spx(30),
          orient: 'vertical',
          textStyle: {
            color: '#fff',
          },
        },
        series: [
          {
            type: 'pie',
            radius: [this.$px2spx(40), this.$px2spx(80)],
            center: ['37%', '50%'],
            // roseType: 'area',
            itemStyle: {
              borderRadius: this.$px2spx(2),
            },
            label: {
              show: true,
              formatter: '{d}%',
              color: '#fff',
            },
            data: [],
          },
        ],
      },
      chartsOption3: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['年度累计完成', '月底完成'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        series: [
          {
            name: '年度累计完成',
            type: 'line',
            color: '#f6c26e',
            data: [],
          },
          {
            name: '月底完成',
            type: 'line',
            color: '#1ef2f3',
            data: [],
          },
        ],
      },
      chartsOption4: {
        tooltip: {},
        xAxis: {
          data: [],
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          data: ['年度累计完成', '月底完成'],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#1d1e42', //垂直于y轴的线的颜色
              width: 1,
              type: 'solid', //'dotted'虚线 'solid'实线
            },
          },
        },
        series: [
          {
            name: '年度累计完成',
            type: 'line',
            color: '#f6c26e',
            data: [120, 150, 306, 410, 210, 320, 210, 340, 120, 410],
            smooth: true,
            lineStyle: {
              width: this.$px2spx(2),
            },
            showSymbol: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(246,194,110,0.6)',
                },
                {
                  offset: 1,
                  color: 'rgba(246,194,110,0.1)',
                },
              ]),
            },
          },
          {
            name: '月底完成',
            type: 'line',
            color: '#1ef2f3',
            data: [90, 200, 320, 300, 440, 200, 310, 230, 190, 320],
            smooth: true,
            lineStyle: {
              width: this.$px2spx(2),
            },
            showSymbol: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(30,242,243,0.5)',
                },
                {
                  offset: 1,
                  color: 'rgba(30,242,243,0.1)',
                },
              ]),
            },
          },
        ],
      },
      showEnergy: true,
      showPassenger: true,
    }
  },
  watch: {
    '$route.params': {
      //监听路由可以通过参数的不同进行判断是否需要重新加载数据
      //用于缓存动态路由页面的时候id发生变化数据未重新请求接口
      deep: true,
     async handler() {
        let that = this;
       that.hallFile = []
        if (this.$route.matched[1].path === '/dataAnalyse/:id/:hallName') {
          this.getData()
          this.currHallName = this.$route.params.hallName
          //获取展厅文件
          let ht = await detailHallApi({rowId:this.$route.params.id})
          if(ht.data.hallInfo)that.hallFile = ht.data.hallInfo.split(";").filter(function(n) { return n; });
          that.hallCity = ht.data.hallCity+ht.data.hallAddress
          this.$nextTick(() => {
            // dom元素更新后执行，因此这里能正确打印更改之后的值

            //播放mp4
            this.playMp4()

            //地图定位
            that.geocoder.getLocation(that.hallCity, function(status, result) {
              if (status === 'complete'&&result.geocodes.length) {
                var lnglat = result.geocodes[0].location
                that.marker.setPosition(lnglat);
                that.map.add(that.marker);
                that.map.setFitView(that.marker);
              }else{
                log.error('根据地址查询位置失败');
              }
            });
          })

        }
      },
      immediate: true,
    },
  },
  async mounted() {
    let that = this
    this.currHallName = this.$route.params.hallName
    //获取展厅文件
    let ht = await detailHallApi({rowId:this.$route.params.id})
    if(ht.data.hallInfo)that.hallFile = ht.data.hallInfo.split(";").filter(function(n) { return n; });
    that.hallCity = ht.data.hallCity+ht.data.hallAddress
    //初始化地图
    that.map = new AMap.Map('map', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      mapStyle: "amap://styles/darkblue"
    });
    that.geocoder = new AMap.Geocoder({
      city: "全国", //城市设为北京，默认：“全国”
    });
    that.marker = new AMap.Marker();
    that.geocoder.getLocation(that.hallCity, function(status, result) {
      if (status === 'complete'&&result.geocodes.length) {
        var lnglat = result.geocodes[0].location
        that.marker.setPosition(lnglat);
        that.map.add(that.marker);
        that.map.setFitView(that.marker);
      }else{
        log.error('根据地址查询位置失败');
      }
    });

    //刷新组件  fileChart
    const fileChart = this.$refs.fileChart
    const guestCharts = this.$refs.guestCharts
    const optCharts = this.$refs.optCharts
    const energyCharts = this.$refs.energyCharts
    window.addEventListener('resize',function(){
      // 调用子组件的方法
      fileChart.resize()
      optCharts.resize();
      guestCharts.resize();
      energyCharts.resize();
    })

    this.playMp4()

  },
  methods: {
    //如果无法自动播放，需点击浏览器网址左侧：  "不安全 - 网站设置 -  声音  改成允许。" 就可以自动播放
    playMp4(){
      if(this.hallFile[0] && this.hallFile[0].indexOf('.mp4')>0){
        var player = new Aliplayer({
          id: 'J_prismPlayer',
          source: this.hallFile[0],
          "autoplay": true,
          "isLive": false,
          "rePlay": true,
          "playsinline": true,
          "preload": true,
          "controlBarVisibility": "never",
          "useH5Prism": true
        },function(player){
          console.log('The player is created.')
        });
      }
    },
    prevClick(){
      if(this.imgIndex >0){
        this.imgIndex = this.imgIndex -1;
      }else{
        this.imgIndex = this.hallFile.length-1;
      }
    },
    nextClick(){
      if(this.imgIndex < this.hallFile.length-1){
        this.imgIndex = this.imgIndex  + 1;
      }else{
        this.imgIndex = 0;
      }
    },
    async getData() {
      let res = await dataAnalyseDataApi({
        hallId: this.$route.params.id,
      })
      let resource = res.data.fileTypeData
      let device = res.data.optStatusData
      let energyConsumption = res.data.electricityData
      let passenger = res.data.guestsData

      let cO1 = {
        data: [],
        series: [[], []],
      }
      //资源文件统计
      resource.forEach((item) => {
        cO1.data.push(item.x)
        cO1.series[0].push(item.y1)
        cO1.series[1].push(item.y2)
      })
      this.chartsOption1.xAxis.data = cO1.data
      cO1.series.forEach((item, index) => {
        this.chartsOption1.series[index].data = item
      })
      //设备统计
      let cO2 = []
      device.forEach((item) => {
        cO2.push({
          name: item.x,
          value: item.y1,
        })
      })
      this.chartsOption2.series[0].data = cO2
      console.info(cO2)

      // 能耗分析

      if (energyConsumption.length !== 0) {
        let cO3 = {
          data: [],
          series: [[], []],
        }

        energyConsumption.forEach((item) => {
          cO3.data.push(item.date + '月')
          cO3.series[0].push(item.year)
          cO3.series[1].push(item.month)
        })
        this.chartsOption3.xAxis.data = cO3.data
        cO3.series.forEach((item, index) => {
          this.chartsOption3.series[index].data = item
        })
      } else {
        this.showEnergy = false
      }

      if (passenger.length !== 0) {
        // 客流统计
        let cO4 = {
          data: [],
          series: [[], []],
        }
        passenger.forEach((item) => {
          cO4.data.push(item.date + '月')
          cO4.series[0].push(item.year)
          cO4.series[1].push(item.month)
        })
        this.chartsOption4.xAxis.data = cO4.data
        cO4.series.forEach((item, index) => {
          this.chartsOption4.series[index].data = item
        })
      } else {
        this.showPassenger = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .hallImg{
    width: 100%;
    height: calc((100vh-80px)*0.66);
    margin: 0px auto ;
    background-image: url('../assets/images/imgbg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .leftImg{
    float: left;
    width: 100px;
    padding-top:260px
  }
  .centerImg{
    float: left;
    width: calc(100% - 201px);
    height: 100%;
  }
  .rightImg{
    float: left;
    width: 100px;
    padding-top:260px;
  }
.data-analyse-page {
  width: 100%;
  padding: 10px;
  border: 1px solid #092a6e;
  box-shadow: 0 0 20px 6px #090f3b inset;

  .top-title-box {
    margin-top: 10px;
    margin-bottom: 20px;

    .zebra-box {
      width: 200px;
    }

    .title-text {
      color: #1ef2f3;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .chunk-item {
    margin: 1px auto;
    width: 95%;
    /*min-height: 300px;*/
    height: calc((100vh-80px)*0.33);
    padding: 5px;
    background-image: url('../assets/images/home-chunk-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .no-data-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 10px;
      color: #999;
      font-size: 14px;
      height: calc(100% - 50px);
      .no-data-img {
        margin-top: 20px;
        opacity: 0.5;
        width: 150px;
      }
    }

    .title-box {
      height: 45px;
      margin-bottom: 15px;
      overflow: hidden;
      flex-wrap: nowrap;

      .title-text {
        font-size: 22px;
        color: #fff;
        margin-right: 12px;
      }

      .title-line {
        flex: 1;
        /*height: 14px;*/
        /*background-image: url("../assets/images/title-line.png");*/
        /*background-size: 402px 14px;*/
        /*background-repeat: no-repeat;*/
      }
    }

    .echart-view {
      height: calc(100% - 60px);
    }
  }
}
</style>
