import axios from '@/libs/api.request'
//首页初始化数据
export const homeInitDataApi = (params) => {
  return axios.request({
    url: 'home/list.do',
    params,
    method: 'get',
  })
}

//数据分析接口
export const dataAnalyseDataApi = (data) => {
  return axios.request({
    url: 'data_analyse_data',
    data,
    method: 'get',
  })
}

//实时播放,获取视频列表
export const realTimePlayingDataApi = (data) => {
  return axios.request({
    url: 'real_time_playing_data',
    data,
    method: 'get',
  })
}

// 获取主题
export const listThemes = (data) => {
  return axios.request({
    url: 'themes/listThemes.do',
    data,
    method: 'post',
  })
}
