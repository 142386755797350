<template>
  <Poptip v-model="showPoptip" word-wrap popper-class="trash-affirm-poptip" :placement="placement" :disabled="disabled" :transfer="transfer">
    <slot name="body"></slot>
    <div slot="content">
      <Alert type="warning" show-icon>{{title}}</Alert>
      <div class="flex ju-c-end"><Button class="trash-btn off-line-text" type="text" ghost size="small" :loading="loading" @click="ok">删除</Button></div>
    </div>
  </Poptip>
</template>

<script>
export default {
  name: 'trashAffirmCom',
  props: {
    placement: {
      type: String,
      default: 'left',
    },
    transfer: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    title: {
      type: String,
      default: '确定要删除这条吗?',
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  data() {
    return {
      showPoptip: false,
      loading: false,
    }
  },
  methods: {
    async ok() {
      this.loading = true
      this.$emit('confirm', this)
    },
    trashSuccess() {
      this.loading = false
      this.showPoptip = false
    },
  },
}
</script>
<style lang="scss">
.trash-affirm-poptip {
  .trash-btn {
    overflow: hidden;
  }
  .ivu-poptip-arrow {
    &::after {
      border-left-color: #090f3b !important;
    }
  }
  .ivu-poptip-inner {
    background-color: rgba($color: #090f3b, $alpha: 0.8);
    border: 1px solid #092a6e;
    box-shadow: 0 0 20px 6px #090f3b inset;
  }
  .ivu-poptip-body {
    text-align: left;
    padding: 10px;
    .ivu-alert-warning {
      border: 0;
      background-color: unset;
      .ivu-alert-icon {
        top: 8px;
        left: 20px;
      }
      .ivu-alert-message {
        color: #fff;
      }
    }
  }
  .ivu-poptip-title:after {
    display: none;
  }
  .ivu-poptip-footer {
    .ivu-btn-text {
      display: none;
    }
  }
}
</style>